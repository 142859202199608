import { useEffect, useReducer, useState } from 'react';
// Hooks
import { useAuth } from './useAuth';
// Resources
import {
  activateCouponNFT,
  checkCouponActive,
  getCouponGasFee,
  getBlockchainConfigurations,
  getExternalNfts,
  getNftCollections,
  getNftGasFee,
  getNfts,
  resendCouponCode,
  transferNft,
} from '@/resources/nft-service.resource';
// Utils
import {
  initNftState,
  initNftGasState,
  initError,
  nftReducer,
  nftErrorHandler,
} from './helpers/nftHelpers';
import { getActivationExpirationDate } from '@/utils/dayjs';

export function useNfts({ chainId }) {
  const { fetcher } = useAuth();
  const [state, dispatch] = useReducer(nftReducer, initNftState);
  const [gasFee, setGasFee] = useState(initNftGasState);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    getBlockchainsAndCollections();
  }, []);

  const handleSetGasState = (data) =>
    setGasFee((prevState) => ({
      ...prevState,
      ...data,
    }));

  const setNftError = (error = false) =>
    dispatch({ type: 'SET_ERROR', payload: error ?? initError });

  const getBlockchainsAndCollections = async () =>
    await fetcher(getBlockchainConfigurations())
      .then(({ blockchainConfigurations }) => {
        const blockchains = {};
        blockchainConfigurations.forEach((blockchain) => {
          blockchains[blockchain.chainId] = blockchain;
        });
        return blockchains;
      })
      .then(async (blockchains) => {
        const collections = await fetcher(getNftCollections());
        collections.forEach((collection) => {
          blockchains[collection.chainId] = {
            ...blockchains[collection.chainId],
            collections: {
              ...blockchains[collection.chainId]?.collections,
              [collection.contractAddress]: {
                ...collection,
                nfts: {},
              },
            },
          };
        });

        dispatch({
          type: 'SET_BLOCKCHAINS',
          payload: blockchains,
        });
        dispatch({ type: 'SET_CONTRACT_IDS', payload: collections });
      });

  const buildNftUrls = (chainId, collection, id) => {
    const blockchain = state.blockchains[chainId];
    const url = blockchain?.blockExplorerUrl;
    const isL2 = blockchain?.isDefaultLayer2;
    return {
      collection: `${url}/token/${collection}`,
      token: `${url}/${isL2 ? 'collections' : 'nft'}/${collection}/${id}`,
    };
  };

  const handleFetchInternalNfts = async ({
    address,
    contractIds = [],
    isCore = true,
  }) => {
    if (!contractIds?.length) return [];
    const promises = contractIds.map(
      ({ contractAddress: contractId, chainId, internalName }) =>
        fetcher(getNfts({ address, chainId, contractId }))
          .then((nfts) => {
            if (!nfts.length) return [];

            return nfts?.map((nft) => ({
              ...nft,
              contractId,
              chainId,
              isCore,
              isExternal: false,
              ownedBy: address,
              collection: internalName || '',
              links: buildNftUrls(chainId, contractId, nft.id),
              useEthGas: state.blockchains[chainId].isDefaultLayer1,
              ...(nft?.activatedAtEpochSeconds
                ? {
                    boostDates: getActivationExpirationDate(
                      nft.activatedAtEpochSeconds,
                      nft.attributes.find((attr) => attr.trait_Type.includes('Duration'))
                        ?.value || 0
                    ),
                  }
                : {}),
            }));
          })
          .catch(nftErrorHandler(setNftError))
    );
    const responses = await Promise.all(promises);
    const nfts = responses.reduce((acc, val) => [...acc, ...val], []);
    return nfts;
  };

  const handleFetchExternalNfts = async ({ address, chainId, isCore = true }) =>
    await fetcher(getExternalNfts({ address, chainId }))
      .then((nfts) => {
        if (!nfts.length) return [];
        return nfts?.map((nft) => ({
          ...nft,
          chainId,
          isCore,
          ownedBy: address,
          contractId: nft.contractAddress,
          isExternal: true,
          links: buildNftUrls(chainId, nft.contractAddress, nft.id),
          useEthGas: true,
        }));
      })
      .catch(nftErrorHandler(setNftError));

  const fetchNfts = async ({ address, loading = false, isCore = true }) => {
    if (loading) dispatch({ type: 'SET_LOADING', payload: true });

    const nfts = await handleFetchInternalNfts({
      contractIds: state.contractIds,
      address,
      isCore,
    });
    const externalNfts = await handleFetchExternalNfts({ address, chainId, isCore });

    const filteredNfts = externalNfts.filter((nft) => !nfts.some((n) => n.id === nft.id));

    dispatch({
      type: isCore ? 'SET_CORE_NFT_STATE' : 'SET_LEGACY_NFT_STATE',
      payload: { nfts, externalNfts: filteredNfts },
    });
  };

  const handleGetNftsByWallet = async ({
    core,
    legacy,
    hasTwoWallets,
    hasNoWallet,
    hasLegacyOnly,
    isRefreshing = false,
  }) => {
    if (hasNoWallet) return;
    setIsRefreshing(isRefreshing);

    try {
      if (hasTwoWallets) {
        await fetchNfts({ address: core });
        await fetchNfts({ address: legacy, isCore: false });
      } else {
        await fetchNfts({
          address: hasLegacyOnly ? legacy : core,
          isCore: !hasLegacyOnly,
        });
      }
    } catch (error) {
      setNftError(error);
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
      dispatch({ type: 'SET_TRANSFER_SUCCESS', payload: false });
      setIsRefreshing(false);
    }
  };

  // Fetch transfer gas fee
  const fetchGasFee = async ({ data }) => {
    handleSetGasState({ loading: true });

    await fetcher(getNftGasFee({ ...data })).then((value) => {
      handleSetGasState({
        value,
        loading: false,
        error: initError,
      });
    });
  };

  const handleTransfer = async ({ data }) =>
    await fetcher(transferNft({ ...data })).then(() => {
      handleSetGasState(initNftGasState);
      dispatch({ type: 'SET_TRANSFER_SUCCESS', payload: true });
    });

  const handleCoupon = async ({ data, action = 'activate' }) => {
    const actions = {
      activate: activateCouponNFT,
      check: checkCouponActive,
      gasFee: getCouponGasFee,
      resend: resendCouponCode,
    };
    return await fetcher(actions[action]({ ...data })).then((data) => {
      if (action === 'gasFee') {
        handleSetGasState({
          value: data,
          loading: false,
        });
      }
      // Set transfer success state to trigger nfts reload
      if (action === 'activate')
        dispatch({ type: 'SET_TRANSFER_SUCCESS', payload: true });
      return data;
    });
  };

  const findNftByIds = (id, contractId) => {
    const coreNfts = [...state.core.nfts, ...state.core.externalNfts];
    const legacyNfts = [...state.legacy.nfts, ...state.legacy.externalNfts];
    const allNfts = [...coreNfts, ...legacyNfts];

    const nftMatch = (nft) => nft.id === id && nft.contractId === contractId;
    const nft = allNfts.find(nftMatch);
    return nft;
  };

  return {
    dispatch,
    fetchGasFee,
    fetchNfts,
    findNftByIds,
    gasFee,
    handleCoupon,
    handleTransfer,
    state,
    handleGetNftsByWallet,
    setNftError,
    isRefreshing,
    resetGasFee: () => handleSetGasState(initNftGasState),
    clearError: () => setNftError(),
  };
}
